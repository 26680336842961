(function() {
    'use strict';

    angular.module('ionicSettings', [
        'ionicAlert',
        'ionicForceRefresh',
        'ionicLogin',
        'ionicProject',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicSettings').service('SettingsService', SettingsService);

    SettingsService.$inject = [
        'DataManagementService',
        'DefaultVariableService',
        'LoginService',
        'ProjectService',
        'RequestManagementService'
    ];

    function SettingsService(
        DataManagementService,
        DefaultVariableService,
        LoginService,
        ProjectService,
        RequestManagementService
    ) {
        var SettingsService = this;

        SettingsService.getSetting = getSetting;
        function getSetting(names, options) {
            names = DefaultVariableService.getArray(names);

            return SettingsService.getSettings(options).then(
                function(settings) {
                    if (settings) {
                        var values = SettingsService.getValues(names, settings);
                        if (values) {
                            if (values.length === 1) {
                                return values[0];
                            }

                            return values;
                        }

                        return false;
                    }

                    return false;
                }
            );
        }

        SettingsService.getSettings = getSettings;
        function getSettings(options) {
            if (DefaultVariableService.isDefined(options)) {
                return SettingsService.loadSettings(options);
            } else {
                options = RequestManagementService.getRequest(options);

                return LoginService.getActiveUser().then(
                    function (activeUser) {
                        if (activeUser) {
                            var groupId = DefaultVariableService.getInteger(
                                activeUser.group_id
                            );
                            if (groupId !== 0) {
                                options = RequestManagementService.setParam(
                                    options,
                                    'group_id=' + groupId
                                );
                            }

                            var userId = DefaultVariableService.getInteger(
                                activeUser.id
                            );
                            if (userId !== 0) {
                                options = RequestManagementService.setParam(
                                    options,
                                    'user_id=' + userId
                                );
                            }

                            var userTypeId = DefaultVariableService.getInteger(
                                activeUser.user_type_id
                            );
                            if (userTypeId !== 0) {
                                options = RequestManagementService.setParam(
                                    options,
                                    'user_type_id=' + userTypeId
                                );
                            }
                        }

                        return ProjectService.getProject().then(
                            function (project) {
                                if (project) {
                                    var projectId = DefaultVariableService.getInteger(
                                        project.id
                                    );
                                    if (projectId !== 0) {
                                        options = RequestManagementService.setParam(
                                            options,
                                            'project_id=' + projectId
                                        );
                                    }
                                }

                                return SettingsService.loadSettings(options);
                            }
                        );
                    }
                );
            }
        }

        SettingsService.getValue = getValue;
        function getValue(name, settings) {
            var setting = DefaultVariableService.get(
                settings[name],
                false
            );

            var value = false;
            if (setting) {
                value = DefaultVariableService.get(
                    setting.value,
                    false
                );
            }

            return value;
        }

        SettingsService.getValues = getValues;
        function getValues(names, settings) {
            var values = [];

            names = DefaultVariableService.getArray(names);

            var namesLength = names.length;
            for (var i = 0; i < namesLength; i++) {
                var name = names[i];

                values.push(
                    SettingsService.getValue(name, settings)
                );
            }

            return values;
        }

        SettingsService.loadSettings = loadSettings;
        function loadSettings(options) {
            options = RequestManagementService.getRequest(options);

            options = RequestManagementService.setModel(options, 'settings');
            options = RequestManagementService.setAction(options, 'get');

            return DataManagementService.request(options).then(
                function(data) {
                    if (data) {
                        var settings = DefaultVariableService.getArray(
                            data.settings
                        );

                        if (settings.length !== 0) {
                            settings = settings[0];
                        }

                        return settings;
                    }

                    return false;
                }
            );
        }

        SettingsService.editSettings = editSettings;
        function editSettings(settings, project) {
            return ProjectService.getProject(project).then(
                function(project) {
                    if (project) {
                        var options = {};
                        options = RequestManagementService.setData(
                            options,
                            {
                                settings: settings
                            }
                        );

                        options = RequestManagementService.setModel(options, 'settings');
                        options = RequestManagementService.setAction(options, 'update');
                        options = RequestManagementService.setSubAction(options, project.id);

                        return DataManagementService.request(options);
                    }

                    return false;
                }
            );
        }

        SettingsService.reset = reset;
        function reset() {

        }

        SettingsService.reset();

        return SettingsService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicSettings').controller('IonicSettingsController', IonicSettingsController);

    IonicSettingsController.$inject = [
        'AlertService',
        'DefaultVariableService',
        'SettingsService',
        'LoginService',
        '$scope'
    ];

    function IonicSettingsController(
        AlertService,
        DefaultVariableService,
        SettingsService,
        LoginService,
        $scope
    ) {
        var IonicSettingsController = this;

        $scope.$watch(
            'options',
            function(options) {
                IonicSettingsController.loadSettings(options);
            }
        );

        IonicSettingsController.clear = clear;
        function clear() {
            SettingsService.reset();
            IonicSettingsController.reset();
        }

        IonicSettingsController.forceRefresh = forceRefresh;
        function forceRefresh() {
            IonicSettingsController.clear();
            IonicSettingsController.init();
        }

        IonicSettingsController.loadSettings = loadSettings;
        function loadSettings(options) {
            IonicSettingsController.isLoadingSettings = true;

            return SettingsService.getSettings(options).then(
                function(settings) {
                    if (settings) {
                        IonicSettingsController.settings = settings;
                    }

                    return settings;
                }
            ).finally(
                function() {
                    IonicSettingsController.isLoadingSettings = false;
                }
            );
        }

        IonicSettingsController.editSettings = editSettings;
        function editSettings(settings) {
            settings = DefaultVariableService.get(
                settings,
                IonicSettingsController.settings
            );

            IonicSettingsController.isEditingSettings = true;

            return SettingsService.editSettings(settings).then(
                function(data) {
                    if (data) {
                        AlertService.addMessage(IonicSettingsController.onUpdateMessage);
                    }

                    return data;
                }
            ).finally(
                function() {
                    IonicSettingsController.isEditingSettings = false;
                }
            );
        }

        IonicSettingsController.reset = reset;
        function reset() {
            IonicSettingsController.isUpdatingSettings = false;

            IonicSettingsController.isEditingSettings = false;

            IonicSettingsController.onUpdateMessage = DefaultVariableService.getString(
                IonicSettingsController.onUpdateMessage,
                'SETTINGS UPDATED.'
            );
        }

        IonicSettingsController.init = init;
        function init() {
            IonicSettingsController.reset();
        }

        LoginService.register(IonicSettingsController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicSettings').directive('ionicSettings', ionicSettings);

    function ionicSettings() {
        return {
            bindToController: {
                onUpdateMessage: '@'
            },
            controller:   'IonicSettingsController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope:        {
                options: '='
            },
            template:'<ion-list><ionic-force-refresh refresh-function="ctrl.forceRefresh"></ionic-force-refresh><div class="item item-toggle" data-ng-repeat="setting in ctrl.settings"><span data-ng-click>{{ setting.display_name }}</span> <label class="toggle"><input type="checkbox" data-ng-model="setting.value"><div class="track accept-color"><div class="handle"></div></div></label></div></ion-list><span data-ng-if="ctrl.isLoadingSettings"><ion-spinner class="centerSpinner"></ion-spinner></span><div data-ng-if="!ctrl.isLoadingSettings"><button class="button button-full button-balanced accept-button" data-ng-click="ctrl.editSettings()" data-ng-disabled="ctrl.isEditingSettings">{{ ctrl.isEditingSettings ? \'SAVING\' : \'SAVE\' }}</button></div>'
        };
    }
})();